var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"1.0.938"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

const buildId = publicRuntimeConfig.BUILD_ID
const SENTRY_DSN = process.env.CLIENT_SENTRY_DSN || process.env.NEXT_PUBLIC_CLIENT_SENTRY_DSN

Sentry.init({
  dsn: SENTRY_DSN || 'https://989361b24bec4d68bf228a1daafa3c06@o174136.ingest.sentry.io/1270270',
  environment: buildId.split('-')[1],
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps

  beforeSend(event, hint) {
    const error = hint.originalException

    // Handle non-Error exceptions (like DOM events)
    if (error && !(error instanceof Error)) {
      // If it's an Event object
      if (error instanceof Event) {
        const newError = new Error('DOM Event Exception')
        newError.name = 'DOMEventError'
        newError.eventType = error.type
        newError.eventTarget = error.target?.tagName
        newError.eventPhase = error.eventPhase
        newError.eventDetail = error.detail

        // Add the original event properties as extra data
        event.extra = {
          ...event.extra,
          eventProperties: {
            currentTarget: error.currentTarget,
            target: error.target,
            detail: error.detail,
            isTrusted: error.isTrusted,
            type: error.type,
            timeStamp: error.timeStamp,
          },
        }

        // Update the event with our transformed error
        event.exception.values[0].value = newError.message
        event.exception.values[0].type = newError.name
        return event
      }

      // For other non-Error objects
      const newError = new Error('Non-Error Exception')
      newError.name = 'NonErrorException'
      newError.originalValue = error

      event.exception.values[0].value = newError.message
      event.exception.values[0].type = newError.name
      event.extra = {
        ...event.extra,
        originalValue: error,
      }
      return event
    }

    return event
  },

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.005,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 0.2,

  // so we can have more details on nested objects
  normalizeDepth: 5,

  // ignoreErrors and denyUrls are based on the gist from https://gist.github.com/impressiver/5092952#gistcomment-3759198
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    // Generic error code from errors outside the security sandbox
    // You can delete this if using raven.js > 1.0, which ignores these automatically.
    'Script error.',
    // Avast extension error
    '_avast_submit',

    // RULER project specific:

    // This one is thrown from pages that have forms.
    // The working hypothesis is that it's caused from some input manipulation from extensions - password managers, browser form helpers, etc.
    // Other people are experiencing this too: https://github.com/getsentry/sentry-javascript/issues/3440
    'Non-Error promise rejection captured',

    // Caused by gtag.js
    // https://stackoverflow.com/questions/67224859/typeerror-illegal-invocation
    'Illegal invocation',
  ],
  denyUrls: [
    // Google Adsense
    /pagead\/js/i,
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
})
